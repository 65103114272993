import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Text from 'components/Text'
import SpinnerLoading from 'components/SpinnerLoading'

import { CreateAttendanceContext } from 'hooks/CreateAttendanceContext'
import { ReactComponent as BackIcon } from 'assets/icons/ic-backarrow.svg'

import Steps from './Steps'
import * as s from './styles'
import axiosInstance from 'services/api'
import ModalConfirm from 'components/ModalConfirm'
import ModalMessage from 'components/ModalMessage'
import OfflineBar from 'components/OfflineBar'
import { ConnectivityContext } from 'hooks/ConnectivityContext'
import { useGet } from 'restful-react'
import axios from 'services/api'

const CreateAttendance = () => {
  const history = useHistory()
  const [isLoading, setLoading] = useState(false)
  const {
    currentStep,
    setCurrentStep,
    formData,
    setFormData,
    setPartnerId,
    partnerId
  } = useContext(CreateAttendanceContext)
  const { isOnline } = useContext(ConnectivityContext)

  const [partner, setPartner] = useState([
    {
      id: '',
      name: ''
    }
  ])

  const [validated, setValidated] = useState(false);
  const [loadingValidate, setLoadingValidate] = useState<boolean>(false);

  const {
    loading: loadingPartners,
    data: partners,
    refetch: getPartners
  } = useGet('/companyclient', { lazy: true })

  // const { data:userData } = useGet('beneficiary/getdata')

  const validateAge = async () => {
    const userData = await axios.get('/beneficiary/getdata');

    if (userData.data?.birthDate?.age < 12) {
      history.push('/validacao-idade')
    } else {
      setValidated(true);
    }
  }

  useEffect

  const getValidation = async () => {
    setLoadingValidate(true);
    const isValid = await axios.post('/beneficiary/checkPatientUpdateDate')
    setLoadingValidate(false)

    if (isValid.data) {
      validateAge();
    } else {
      history.push('/validacao')
    }
  }

  useEffect(() => {
    if (!currentStep) {
      getPartners()
    }
  }, [currentStep])

  useEffect(() => {
    if (partners) {
      setPartner(partners)
    }
  }, [partners])

  useEffect(() => {
    if (!validated) {
      getValidation();
    }
  }, []);

  useEffect(() => {
    if (validated) {
      const checkStep = async (): Promise<void> => {
        const urlParams = new URLSearchParams(history.location.search)

        const usageOfInterviewAllowed = urlParams.get('usageOfInterviewAllowed')
        const fromSymptomChecker = urlParams.get('fromSymptomChecker')
        const symptomCheckerId = urlParams.get('symptomCheckerId')
        const fromUnsuccessful = urlParams.get('fromUnsuccessful')

        if (partnerId) {
          if (
            usageOfInterviewAllowed !== undefined &&
            fromSymptomChecker !== undefined
          ) {
            if (symptomCheckerId !== null) {
              setFormData(prev => ({
                ...prev,
                InterviewId: parseInt(symptomCheckerId)
              }))
            }

            if (
              (fromSymptomChecker == 'true' &&
                usageOfInterviewAllowed == 'true') ||
              (fromUnsuccessful === 'true' && formData.reason !== null)
            ) {
              setCurrentStep('DATE_HOUR')
            } else if (
              (fromSymptomChecker == 'true' &&
                usageOfInterviewAllowed == 'false') ||
              fromUnsuccessful === 'true'
            ) {
              setCurrentStep('SYMPTOMS')
            } else if (fromSymptomChecker && usageOfInterviewAllowed === null) {
              setLoading(true)

              try {
                const result = await axiosInstance.get(
                  'beneficiary/checkelegibility'
                )

                const handleConfirmation = (): void => {
                  axiosInstance.get(
                    `/symptomchecker/${symptomCheckerId}/grantpermission`
                  )
                  setCurrentStep('DATE_HOUR')
                }

                const handleCancel = (): void => {
                  setCurrentStep('SYMPTOMS')
                }

                if (result.data) {
                  ModalConfirm({
                    title: 'Permitir acesso',
                    message:
                      'O profissional de saúde pode ter acesso a todos os dados recém informados?',
                    cancelButtonText: 'Não',
                    handleConfirm: handleConfirmation,
                    handleCancel
                  })
                } else {
                  ModalMessage({
                    title: 'Ops, não encontramos o seu benefício',
                    message:
                      'Isso pode ocorrer quando sua conta ou nossos serviços não estão mais disponíveis com o parceiro que está vinculado. Conheça nossas assinaturas ou outros parceiros disponíveis para continuar com o seu atendimento.',
                    buttonText: 'Entrar em contato com nosso time',
                    onClose: () =>
                      window.open(
                        'https://docway.com.br/contato-empresa/',
                        '_blank'
                      )
                  })
                }
              } catch {
                setCurrentStep('SYMPTOMS')
              } finally {
                setLoading(false)
              }
            } else {
              setCurrentStep('SYMPTOMS')
            }
          }
        } else {
          setCurrentStep(null)
        }
      }

      checkStep()
    }
  }, [history.location, setFormData, setCurrentStep, setLoading, partnerId, validated])

  function handleBack() {
    history.push('/dashboard')
  }

  function handleNextStep(partnerId) {
    setFormData({
      ...formData,
      partnerId
    })
    setPartnerId(partnerId)
  }

  return !currentStep ? (
    isLoading || loadingPartners || loadingValidate || !validated ? (
      <SpinnerLoading></SpinnerLoading>
    ) : (
      <>
        <s.Page>
          <s.Header>
            <BackIcon
              width={30}
              height={30}
              fill="gray"
              onClick={() => handleBack()}
            />
          </s.Header>
        </s.Page>
        <OfflineBar />
        <s.Page>
          <s.Content>
            <Text type="h2_semibold">
              Seu perfil tem vínculo com um de nossos parceiros
            </Text>
            <br />
            <Text type="p2_regular">
              Selecione a empresa para continuar o agendamento da sua consulta!
            </Text>

            {partner[0].id !== ''
              ? partner.map(partners => (
                  <s.Item
                    key={partners.id}
                    onClick={() =>
                      isOnline ? handleNextStep(partners.id) : null
                    }
                  >
                    <Text>{partners.name}</Text>
                  </s.Item>
                ))
              : null}
          </s.Content>
        </s.Page>
      </>
    )
  ) : (
    <Steps />
  )
}

export default CreateAttendance
